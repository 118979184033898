<template>
  <div class="year-count">
    <div class="chart-header">
      <h3>Steam游戏发售年份分析</h3>
      <p class="subtitle">分析Steam平台上游戏的发售年份分布</p>
    </div>

    <div class="charts-grid">
      <!-- 柱状图 -->
      <div class="chart-card">
        <div class="card-header">
          <h4>年份分布</h4>
          <el-tooltip content="展示各年份发售的游戏数量" placement="top">
            <i class="el-icon-info"></i>
          </el-tooltip>
        </div>
        <div class="chart-container" ref="barChart" v-loading="loading"></div>
      </div>

      <!-- 饼图 -->
      <div class="chart-card">
        <div class="card-header">
          <h4>年份占比</h4>
          <el-tooltip content="展示各年份发售游戏的占比" placement="top">
            <i class="el-icon-info"></i>
          </el-tooltip>
        </div>
        <div class="chart-container" ref="pieChart" v-loading="loading"></div>
      </div>

      <!-- 折线图 -->
      <div class="chart-card full-width">
        <div class="card-header">
          <h4>发售趋势</h4>
          <el-tooltip content="展示游戏发售数量的变化趋势" placement="top">
            <i class="el-icon-info"></i>
          </el-tooltip>
        </div>
        <div class="chart-container" ref="lineChart" v-loading="loading"></div>
      </div>
    </div>

    <!-- 数据概览 -->
    <div class="data-overview">
      <div class="overview-card">
        <i class="el-icon-date"></i>
        <div class="overview-content">
          <h4>最早发售年份</h4>
          <p>{{ earliestYear }}</p>
        </div>
      </div>
      <div class="overview-card">
        <i class="el-icon-time"></i>
        <div class="overview-content">
          <h4>最新发售年份</h4>
          <p>{{ latestYear }}</p>
        </div>
      </div>
      <div class="overview-card">
        <i class="el-icon-s-data"></i>
        <div class="overview-content">
          <h4>平均发售年份</h4>
          <p>{{ averageYear }}</p>
        </div>
      </div>
      <div class="overview-card">
        <i class="el-icon-star-on"></i>
        <div class="overview-content">
          <h4>最热门年份</h4>
          <p>{{ topYear }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import axios from "axios";

export default {
  name: "year-count",
  data() {
    return {
      loading: false,
      barChart: null,
      pieChart: null,
      lineChart: null,
      earliestYear: '',
      latestYear: '',
      averageYear: '',
      topYear: '',
      chartData: []
    }
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const res = await axios.get(this.$globle.allurl + "yearcount");
        this.chartData = res.data.sort((a, b) => a.year - b.year);
        this.updateCharts();
        this.calculateStats();
      } catch (error) {
        console.error('获取数据失败:', error);
        this.$message.error('获取数据失败，请重试');
      } finally {
        this.loading = false;
      }
    },
    calculateStats() {
      const years = this.chartData.map(item => parseInt(item.year));
      this.earliestYear = Math.min(...years);
      this.latestYear = Math.max(...years);
      const totalGames = this.chartData.reduce((sum, item) => sum + item.count, 0);
      const weightedSum = this.chartData.reduce((sum, item) => sum + (parseInt(item.year) * item.count), 0);
      this.averageYear = (weightedSum / totalGames).toFixed(1);
      this.topYear = this.chartData.reduce((max, item) => item.count > max.count ? item : max).year;
    },
    initCharts() {
      this.barChart = echarts.init(this.$refs.barChart);
      this.pieChart = echarts.init(this.$refs.pieChart);
      this.lineChart = echarts.init(this.$refs.lineChart);
      window.addEventListener('resize', this.resizeCharts);
    },
    resizeCharts() {
      this.barChart?.resize();
      this.pieChart?.resize();
      this.lineChart?.resize();
    },
    updateCharts() {
      // 柱状图配置
      this.barChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            shadowStyle: {
              color: 'rgba(0, 0, 0, 0.1)'
            }
          }
        },
        grid: {
          left: '5%',
          right: '5%',
          bottom: '15%',
          top: '5%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.chartData.map(item => item.year),
          axisLabel: {
            interval: 0,
            rotate: 45,
            fontSize: 12,
            margin: 14
          }
        },
        yAxis: {
          type: 'value',
          name: '游戏数量',
          nameTextStyle: {
            fontSize: 14,
            padding: [0, 0, 0, 15]
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#E4E7ED'
            }
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#E4E7ED'
            }
          }
        },
        series: [{
          data: this.chartData.map(item => item.count),
          type: 'bar',
          barWidth: '40%',
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#409EFF' },
              { offset: 1, color: '#67C23A' }
            ])
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(64, 158, 255, 0.5)'
            }
          }
        }],
        dataZoom: [
          {
            show: true,
            type: 'slider',
            bottom: 0,
            height: 20,
            start: 0,
            end: 20
          },
          {
            type: 'inside',
            start: 0,
            end: 20
          }
        ]
      });

      // 饼图配置
      this.pieChart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {c} ({d}%)'
        },
        legend: {
          orient: 'vertical',
          right: 10,
          top: 'center',
          type: 'scroll',
          textStyle: {
            fontSize: 12
          },
          pageTextStyle: {
            color: '#666'
          }
        },
        series: [{
          type: 'pie',
          radius: ['40%', '70%'],
          center: ['40%', '50%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '20',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: this.chartData.map(item => ({
            name: item.year,
            value: item.count
          }))
        }]
      });

      // 折线图配置
      this.lineChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        grid: {
          left: '5%',
          right: '5%',
          bottom: '5%',
          top: '10%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.chartData.map(item => item.year),
          axisLabel: {
            interval: 'auto',
            rotate: 45,
            fontSize: 12,
            margin: 14
          }
        },
        yAxis: {
          type: 'value',
          name: '游戏数量',
          nameTextStyle: {
            fontSize: 14,
            padding: [0, 0, 0, 15]
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#E4E7ED'
            }
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#E4E7ED'
            }
          }
        },
        series: [{
          data: this.chartData.map(item => item.count),
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 8,
          lineStyle: {
            width: 3
          },
          itemStyle: {
            color: '#409EFF'
          },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: 'rgba(64, 158, 255, 0.3)' },
              { offset: 1, color: 'rgba(64, 158, 255, 0.1)' }
            ])
          }
        }]
      });
    }
  },
  mounted() {
    this.initCharts();
    this.fetchData();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeCharts);
    this.barChart?.dispose();
    this.pieChart?.dispose();
    this.lineChart?.dispose();
  }
}
</script>

<style scoped>
.year-count {
  padding: 20px;
  background: #f5f7fa;
  min-height: 100vh;
}

.chart-header {
  text-align: center;
  margin-bottom: 30px;
  padding: 20px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.chart-header h3 {
  font-size: 24px;
  color: #2c3e50;
  margin-bottom: 10px;
}

.subtitle {
  color: #666;
  font-size: 14px;
}

.charts-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 30px;
}

.chart-card {
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.chart-card.full-width {
  grid-column: 1 / -1;
}

.chart-card:hover {
  transform: translateY(-5px);
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.card-header h4 {
  margin: 0;
  font-size: 16px;
  color: #2c3e50;
}

.card-header i {
  color: #909399;
  cursor: help;
}

.chart-container {
  height: 450px;
  width: 100%;
}

.data-overview {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 30px;
}

.overview-card {
  display: flex;
  align-items: center;
  padding: 20px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.overview-card:hover {
  transform: translateY(-2px);
}

.overview-card i {
  font-size: 24px;
  color: #409EFF;
  margin-right: 15px;
}

.overview-content h4 {
  margin: 0 0 5px 0;
  font-size: 14px;
  color: #909399;
}

.overview-content p {
  margin: 0;
  font-size: 20px;
  color: #2c3e50;
  font-weight: bold;
}

@media screen and (min-width: 1800px) {
  .charts-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 768px) {
  .chart-container {
    height: 350px;
  }

  .chart-header h3 {
    font-size: 20px;
  }
}
</style>

<template>
  <div class="charts-container">
    <Header index="0"/>
    <div class="charts-content">
      <!-- 左侧图表区域 -->
      <div class="charts-main">
        <div class="charts-header">
          <h2>Steam数据分析</h2>
          <p class="subtitle">探索Steam游戏数据的可视化展示</p>
        </div>

        <el-tabs v-model="activeName" class="charts-tabs">
          <el-tab-pane label="游戏标签统计" name="first">
            <div class="chart-wrapper">
              <div class="chart-header">
                <h3>游戏标签分布</h3>
                <p>展示Steam平台上各类游戏标签的分布情况</p>
              </div>
              <div class="chart-content">
                <tag-count></tag-count>
              </div>
            </div>
          </el-tab-pane>

          <el-tab-pane label="游戏支持语言统计" name="third">
            <div class="chart-wrapper">
              <div class="chart-header">
                <h3>游戏语言支持</h3>
                <p>分析Steam游戏支持的语言分布情况</p>
              </div>
              <div class="chart-content">
                <lang-count></lang-count>
              </div>
            </div>
          </el-tab-pane>

          <el-tab-pane label="游戏评价统计" name="fourth">
            <div class="chart-wrapper">
              <div class="chart-header">
                <h3>游戏评价分析</h3>
                <p>展示Steam游戏的用户评价分布</p>
              </div>
              <div class="chart-content">
                <view-count></view-count>
              </div>
            </div>
          </el-tab-pane>

          <el-tab-pane label="各年份发售游戏统计" name="fifth">
            <div class="chart-wrapper">
              <div class="chart-header">
                <h3>游戏发售年份分布</h3>
                <p>展示不同年份Steam游戏发布数量趋势</p>
              </div>
              <div class="chart-content">
                <year-count></year-count>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>

      <!-- 右侧信息区域 -->
      <div class="charts-sidebar">
        <div class="sidebar-section">
          <h3>数据概览</h3>
          <div class="stats-grid">
            <div class="stat-card">
              <i class="el-icon-data-line"></i>
              <div class="stat-info">
                <span class="stat-value">1000+</span>
                <span class="stat-label">游戏总数</span>
              </div>
            </div>
            <div class="stat-card">
              <i class="el-icon-user"></i>
              <div class="stat-info">
                <span class="stat-value">500万+</span>
                <span class="stat-label">活跃用户</span>
              </div>
            </div>
            <div class="stat-card">
              <i class="el-icon-star-on"></i>
              <div class="stat-info">
                <span class="stat-value">4.5</span>
                <span class="stat-label">平均评分</span>
              </div>
            </div>
          </div>
        </div>

        <div class="sidebar-section">
          <h3>热门游戏</h3>
          <div class="hot-games">
            <div class="hot-game-item" v-for="(game, index) in hotGames" :key="index">
              <img :src="game.image" :alt="game.name">
              <div class="game-info">
                <h4>{{ game.name }}</h4>
                <p>{{ game.price }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tagCount from "@/pages/Charts/tag-count.vue";
import LangCount from "@/pages/Charts/lang-count.vue";
import ViewCount from "@/pages/Charts/view-count.vue";
import YearCount from "@/pages/Charts/year-count.vue";

export default {
  data() {
    return {
      activeName: 'first',
      hotGames: [
        {
          name: "赛博朋克 2077",
          price: "¥298",
          image: "https://media.st.dl.eccdnx.com/steam/apps/1091500/header.jpg"
        },
        {
          name: "艾尔登法环",
          price: "¥298",
          image: "https://media.st.dl.eccdnx.com/steam/apps/1245620/header.jpg"
        },
        {
          name: "荒野大镖客2",
          price: "¥249",
          image: "https://media.st.dl.eccdnx.com/steam/apps/1174180/header.jpg"
        }
      ]
    }
  },
  components: {
    YearCount,
    ViewCount,
    LangCount,
    tagCount
  }
}
</script>

<style scoped>
.charts-container {
  min-height: 100vh;
  background-color: #f5f7fa;
  padding-top: 70px;
}

.charts-content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 20px;
}

/* 左侧主区域样式 */
.charts-main {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.charts-header {
  margin-bottom: 30px;
  text-align: center;
}

.charts-header h2 {
  font-size: 28px;
  color: #2c3e50;
  margin-bottom: 10px;
}

.subtitle {
  color: #666;
  font-size: 16px;
}

.charts-tabs {
  margin-top: 20px;
}

.chart-wrapper {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.chart-header {
  margin-bottom: 20px;
  text-align: center;
}

.chart-header h3 {
  font-size: 20px;
  color: #2c3e50;
  margin-bottom: 8px;
}

.chart-header p {
  color: #666;
  font-size: 14px;
}

.chart-content {
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 右侧边栏样式 */
.charts-sidebar {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.sidebar-section {
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.sidebar-section h3 {
  font-size: 18px;
  color: #2c3e50;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.stat-card {
  display: flex;
  align-items: center;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.stat-card:hover {
  transform: translateY(-2px);
}

.stat-card i {
  font-size: 24px;
  color: #409EFF;
  margin-right: 10px;
}

.stat-info {
  display: flex;
  flex-direction: column;
}

.stat-value {
  font-size: 20px;
  font-weight: bold;
  color: #2c3e50;
}

.stat-label {
  font-size: 12px;
  color: #666;
}

.hot-games {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.hot-game-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background: #f8f9fa;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.hot-game-item:hover {
  transform: translateX(5px);
}

.hot-game-item img {
  width: 60px;
  height: 30px;
  object-fit: cover;
  border-radius: 4px;
}

.game-info {
  flex: 1;
}

.game-info h4 {
  font-size: 14px;
  color: #2c3e50;
  margin-bottom: 4px;
}

.game-info p {
  font-size: 12px;
  color: #ff6602;
  font-weight: bold;
}

/* 响应式设计 */
@media screen and (max-width: 1200px) {
  .charts-content {
    grid-template-columns: 1fr;
  }

  .charts-sidebar {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
}

@media screen and (max-width: 768px) {
  .stats-grid {
    grid-template-columns: 1fr;
  }

  .chart-content {
    min-height: 300px;
  }
}
</style>

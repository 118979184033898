<template>
  <div class="lang-count">
    <div class="chart-header">
      <h3>Steam游戏语言支持分析</h3>
      <p class="subtitle">分析Steam平台上游戏的语言支持情况</p>
    </div>

    <div class="charts-grid">
      <!-- 柱状图 -->
      <div class="chart-card">
        <div class="card-header">
          <h4>语言支持分布</h4>
          <el-tooltip content="展示各语言支持的游戏数量" placement="top">
            <i class="el-icon-info"></i>
          </el-tooltip>
        </div>
        <div class="chart-container" ref="barChart" v-loading="loading"></div>
      </div>

      <!-- 饼图 -->
      <div class="chart-card">
        <div class="card-header">
          <h4>Top 50语言占比</h4>
          <el-tooltip content="展示前50种语言的支持比例" placement="top">
            <i class="el-icon-info"></i>
          </el-tooltip>
        </div>
        <div class="chart-container" ref="pieChart" v-loading="loading"></div>
      </div>
    </div>

    <!-- 数据概览 -->
    <div class="data-overview">
      <div class="overview-card">
        <i class="el-icon-data-line"></i>
        <div class="overview-content">
          <h4>总语言数</h4>
          <p>{{ totalLanguages }}</p>
        </div>
      </div>
      <div class="overview-card">
        <i class="el-icon-s-data"></i>
        <div class="overview-content">
          <h4>平均支持语言数</h4>
          <p>{{ averageLanguages }}</p>
        </div>
      </div>
      <div class="overview-card">
        <i class="el-icon-star-on"></i>
        <div class="overview-content">
          <h4>最受欢迎语言</h4>
          <p>{{ topLanguage }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import axios from "axios";

export default {
  name: "lang-count",
  data() {
    return {
      loading: false,
      barChart: null,
      pieChart: null,
      totalLanguages: 0,
      averageLanguages: 0,
      topLanguage: '',
      chartData: []
    }
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const res = await axios.get(this.$globle.allurl + "languageCount");
        this.chartData = res.data.sort((a, b) => b.count - a.count);
        this.updateCharts();
        this.calculateStats();
      } catch (error) {
        console.error('获取数据失败:', error);
        this.$message.error('获取数据失败，请重试');
      } finally {
        this.loading = false;
      }
    },
    calculateStats() {
      this.totalLanguages = this.chartData.length;
      const totalGames = this.chartData.reduce((sum, item) => sum + item.count, 0);
      this.averageLanguages = (totalGames / this.totalLanguages).toFixed(1);
      this.topLanguage = this.chartData[0]?.language || '未知';
    },
    initCharts() {
      this.barChart = echarts.init(this.$refs.barChart);
      this.pieChart = echarts.init(this.$refs.pieChart);
      window.addEventListener('resize', this.resizeCharts);
    },
    resizeCharts() {
      this.barChart?.resize();
      this.pieChart?.resize();
    },
    updateCharts() {
      // 柱状图配置
      this.barChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            shadowStyle: {
              color: 'rgba(0, 0, 0, 0.1)'
            }
          }
        },
        grid: {
          left: '5%',
          right: '5%',
          bottom: '15%',
          top: '5%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.chartData.map(item => item.language),
          axisLabel: {
            interval: 0,
            rotate: 45,
            fontSize: 12,
            margin: 14
          }
        },
        yAxis: {
          type: 'value',
          name: '游戏数量',
          nameTextStyle: {
            fontSize: 14,
            padding: [0, 0, 0, 15]
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#E4E7ED'
            }
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#E4E7ED'
            }
          }
        },
        series: [{
          data: this.chartData.map(item => item.count),
          type: 'bar',
          barWidth: '40%',
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#409EFF' },
              { offset: 1, color: '#67C23A' }
            ])
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(64, 158, 255, 0.5)'
            }
          }
        }],
        dataZoom: [
          {
            show: true,
            type: 'slider',
            bottom: 0,
            height: 20,
            start: 0,
            end: 10
          },
          {
            type: 'inside',
            start: 0,
            end: 10
          }
        ]
      });

      // 饼图配置
      this.pieChart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {c} ({d}%)'
        },
        legend: {
          orient: 'vertical',
          right: 10,
          top: 'center',
          type: 'scroll',
          textStyle: {
            fontSize: 12
          },
          pageTextStyle: {
            color: '#666'
          }
        },
        series: [{
          type: 'pie',
          radius: ['40%', '70%'],
          center: ['40%', '50%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '20',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: this.chartData.slice(0, 50).map(item => ({
            name: item.language,
            value: item.count
          }))
        }]
      });
    }
  },
  mounted() {
    this.initCharts();
    this.fetchData();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeCharts);
    this.barChart?.dispose();
    this.pieChart?.dispose();
  }
}
</script>

<style scoped>
.lang-count {
  padding: 20px;
  background: #f5f7fa;
  min-height: 100vh;
}

.chart-header {
  text-align: center;
  margin-bottom: 30px;
  padding: 20px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.chart-header h3 {
  font-size: 24px;
  color: #2c3e50;
  margin-bottom: 10px;
}

.subtitle {
  color: #666;
  font-size: 14px;
}

.charts-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 30px;
}

.chart-card {
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.chart-card:hover {
  transform: translateY(-5px);
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.card-header h4 {
  margin: 0;
  font-size: 16px;
  color: #2c3e50;
}

.card-header i {
  color: #909399;
  cursor: help;
}

.chart-container {
  height: 450px;
  width: 100%;
}

.data-overview {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 30px;
}

.overview-card {
  display: flex;
  align-items: center;
  padding: 20px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.overview-card:hover {
  transform: translateY(-2px);
}

.overview-card i {
  font-size: 24px;
  color: #409EFF;
  margin-right: 15px;
}

.overview-content h4 {
  margin: 0 0 5px 0;
  font-size: 14px;
  color: #909399;
}

.overview-content p {
  margin: 0;
  font-size: 20px;
  color: #2c3e50;
  font-weight: bold;
}

@media screen and (min-width: 1800px) {
  .charts-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 768px) {
  .chart-container {
    height: 350px;
  }

  .chart-header h3 {
    font-size: 20px;
  }
}
</style>

import { render, staticRenderFns } from "./lang-count.vue?vue&type=template&id=2352c7ce&scoped=true"
import script from "./lang-count.vue?vue&type=script&lang=js"
export * from "./lang-count.vue?vue&type=script&lang=js"
import style0 from "./lang-count.vue?vue&type=style&index=0&id=2352c7ce&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2352c7ce",
  null
  
)

export default component.exports